<template>
    <div>
        <div class="filter-section">
            <b-form-group label="Application">
                <b-form-select  v-model="selectedApplication" :required="true"
                    :options="applications"></b-form-select>
            </b-form-group>
            <b-form-group label="State">
                <b-form-select v-model="selectedState" :options="states"></b-form-select>
            </b-form-group>
            <b-form-group label="Date Range">
                <b-row>
                    <b-col cols="6">
                        <b-form-datepicker v-model="startDate" placeholder="Start Date"></b-form-datepicker>
                    </b-col>
                    <b-col cols="6">
                        <b-form-datepicker v-model="endDate" placeholder="End Date"></b-form-datepicker>
                    </b-col>
                </b-row>
            </b-form-group>

            <b-form-group label="Event Code">
                <b-form-input v-model="eventCode"></b-form-input>
            </b-form-group>
        </div>

        <div class="filter-section">
            <b-button @click="fetchEvents" variant="primary">Apply Filters</b-button>
        </div>
        <div class="table-section">
            <b-table v-if="dataLoaded" :items="currentPageItems" :fields="tableFields" :filter="searchText" striped
                hover>
                <template #cell(data)="row">
                    <b-button @click="openJsonDataModal(row)" v-b-modal="'modal-' + row.index"
                        variant="">View</b-button>
                    <b-modal ok-only :id="'modal-' + row.index" :title="row.item.type" size="lg" :centered="true"
                        :modal-class="'center-style'">
                        <vue-json-viewer :value="row.item.data" :copyable="true" :preview-mode="true" :expanded="true"
                            v-if="row.item.data"></vue-json-viewer>
                    </b-modal>
                </template>
            </b-table>
            <b-pagination v-if="dataLoaded" v-model="currentPage" :total-rows="filteredData.length" :per-page="perPage"
                align="center"></b-pagination>
        </div>

        <div v-if="loading" class="overlay">
            <div class="spinner"></div>
        </div>
    </div>
</template>

<script>
import VueJsonViewer from 'vue-json-viewer';

export default {
    components: {
        VueJsonViewer,
    },
    data() {
        return {
            loading: false,
            dataLoaded: false,
            startDate: null,
            endDate: null,
            selectedApplication: '',
            selectedState: '',
            searchText: '',
            applications: '',
            states: [{ value: '', text: 'Please select an option' }, 'success', 'info', 'start', 'error'],
            data: [],
            filteredData: [],
            tableFields: ['event_code', 'datetime', 'application', 'type', 'message', 'state', 'data'],
            currentPage: 1,
            eventCode: '',
            perPage: 10,
            jsonDataModal: false,
            jsonData: '',
            selectedData: null,
        };
    },
    computed: {
        currentPageItems() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            return this.filteredData.slice(startIndex, endIndex);
        },
    },
    methods: {
        async applyFilters() { },
        clearFilters() {
            this.startDate = null;
            this.endDate = null;
            this.selectedApplication = '';
            this.selectedState = '';
            this.searchText = '';
            this.eventCode = '';
        },
        onPageChange(page) {
            this.currentPage = page;
        },
        async fetchProjects() {
            try {
                const appList = process.env.VUE_APP_EVENT_APPLIST
                const apps = appList.split(',')
                this.applications = apps
            } catch (error) {
                console.error(error);
            }
        },
        async fetchEvents() {
            try {
                const data = {
                    application: this.selectedApplication,
                    start_date: this.startDate,
                    state: this.selectedState,
                    end_date: this.endDate,
                    eventCode: this.eventCode,
                };

                this.$root.toggleGlobalLoading(true);

                const res = await this.$store.dispatch('app/getEvents', data);

                if (res.status === 500) {
                    this.$toast(this.successToast('Erreur de recuperation des évenements '));
                } else {
                    this.dataLoaded = true
                    this.filteredData = res.data;
                    this.clearFilters();
                    this.$root.toggleGlobalLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        },

        closeJsonDataModal() {
            this.selectedData = null;
            this.jsonDataModal = false;
        },
        openJsonDataModal(data, row) {
            this.selectedData = data;
            this.jsonDataModal = true;
        },
    },
    created() {
        this.fetchProjects();
    },
};
</script>

<style>
.filter-section {
    margin-bottom: 20px;
}

.center-style {
    display: flex;
    place-items: center;
}
</style>
